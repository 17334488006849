import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { getConfig, CONFIG_KEYS } from "./config";

export const initializeLaunchDarkly = async () => {
    const clientSideID = getConfig(CONFIG_KEYS.LAUNCH_DARKLY_CLIENT_ID);

    if (!clientSideID) {
        throw new Error("LaunchDarkly client ID not configured");
    }

    return await asyncWithLDProvider({
        clientSideID,
    });
};
