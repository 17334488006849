import React from "react";
import { withRouter } from "react-router";
import { AddRounded } from "@mui/icons-material";
import { getPathname, getURLObjects, URLSectionInfo } from "../../../utils/url";
import styles from "../../../styles/common/home.module.css";
import Page from "../Page";
import Card from "../Card";
import Button from "../Button";

/**
 * A "Home" is a general page that shows a particular data type, report table, etc
 * - Example: <ContactsHome> shows a report table of all of the "Contacts" in the system
 *
 * @param {string} [cardTitle] - Optional title for the Card component
 * @param {string} [title] - Optional title to override URLSectionInfo title
 * @param {string} [addNewForm] - Creates the link to the "add new" form
 * @param {React.ReactNode} [customCardHeaderContent] - Custom content for the header right side
 * @param {Object|string} [error] - see <Page>
 * @param {boolean} [loading] - see <Page>
 * @param {React.ReactNode} children
 * @param {*} [history]
 */
const Home = ({ cardTitle, title, addNewForm, customCardHeaderContent, loading, error, children, history }) => {
    const pathname = getPathname();
    const { section } = getURLObjects().pop();

    return (
        <Page loading={loading} error={error}>
            <div className={styles.header}>
                {(title || URLSectionInfo[section]?.title) && (
                    <h1 className={styles.title}>{title || URLSectionInfo[section].title}</h1>
                )}
                {addNewForm && (
                    <Button color="primary" size="large" onClick={() => history.push(`${pathname}/${addNewForm}`)}>
                        <AddRounded className={styles.addIcon} /> {URLSectionInfo[addNewForm].title}
                    </Button>
                )}
            </div>
            <Card title={cardTitle} customCardHeaderContent={customCardHeaderContent}>
                {children}
            </Card>
        </Page>
    );
};

export default withRouter(Home);
