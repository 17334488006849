import { useFlags } from "launchdarkly-react-client-sdk";

/**
 * Feature Flags Documentation
 * This file serves as a central registry for all feature flags used in the application.
 *
 * Naming Convention: Use camelCase for flag names as LaunchDarkly returns them in camelCase.
 * Example: pretreatmentEnableNewDashboard
 */

/** EXAMPLE FLAG
 * @flag examplePretreatmentEnableNewDashboard
 * @description Enables the new dashboard interface for the pretreatment module.
 * @created 2024-01-15
 * @expectedRemoval 2024-Q2
 * @dependencies None
 * @usedIn src/components/Pretreatment/Dashboard/*
 */
/**
 * @flag samplingResultsDatabaseShowPage
 * @description Enables showing the sampling results database page.
 * @expectedRemoval 2025-Q2
 * @dependencies None
 * @usedIn src/components/Common/Page.js
 */

// Export flag names as constants appended with 'LD_'
export const FEATURE_FLAGS = {
    // example: LD_EXAMPLE_PRETREATMENT_NEW_DASHBOARD: "examplePretreatmentEnableNewDashboard",
    LD_SAMPLING_RESULTS_DATABASE_SHOW_PAGE: "samplingResultsDatabaseShowPage",
};

/**
 * Custom hook for retrieving a specific feature flag's value.
 * This hook safely retrieves the flag using LaunchDarkly's useFlags.
 * If the flags object is not available (e.g., if the provider hasn't initialized correctly),
 * it logs an error and returns undefined, preventing the application from crashing.
 *
 * @param {string} flagKey - The camelCase key for the feature flag.
 * @returns {any} The value of the feature flag, or undefined if not available.
 */
export const useFeatureFlag = (flagKey) => {
    try {
        const flags = useFlags();

        if (!flags || typeof flags !== "object") {
            console.error(
                "LaunchDarkly flags are not available. Ensure your component is wrapped within the LaunchDarklyProvider."
            );
            return undefined;
        }

        return flags[flagKey];
    } catch (error) {
        console.error("Error retrieving LaunchDarkly feature flag:", error);
        return undefined;
    }
};
