import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaginationOptionsPreferences } from "../../store/actions/swiftComplyActions";

import LastPageIcon from "@mui/icons-material/SkipNext";
import FirstPageIcon from "@mui/icons-material/SkipPrevious";
import PrevPageIcon from "@mui/icons-material/NavigateBefore";
import NextPageIcon from "@mui/icons-material/NavigateNext";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";

const PageSizeOptions = [
    10, 25, 50, 100
];

export const GetClosestPageSizeOption = (opt) => {
    if (! opt) {
        return PageSizeOptions[0]
    }
    return Math.max(...PageSizeOptions.filter( val => val <= opt));
}

const ReportTablePagination = (props) => {
    const dispatch = useDispatch(); // For updating user pagination options prefs
    const dropdownRef = useRef(null);
    const fetchData = props.fetchData;
    const totalRows = props.totalRowCount;
    const isLoading = props.isLoading
    const [page, setPage] = useState(() => props.page);

    const [pageSize, setPageSize] = useState(() => {
        const initialCount = props.pageRowCount;
        return initialCount;
    });

    const [filterJSON, setFilterJSON] = useState(() => props.filterJSON)
    const [searchJSON, setSearchJSON] = useState(() => props.searchJSON)

    const pageSizeOptions = PageSizeOptions.map( (opt)  => {
        return {value: opt, label: String(opt)}
    });

    useEffect(() => {
        setPage(props.page);
        setPageSize(props.pageRowCount);
    }, [props.page, props.pageRowCount]);

    useEffect(() => {
        const cappedPage = totalRows ? Math.min(page, Math.ceil(totalRows / pageSize)) : page;
        fetchData(cappedPage, pageSize)
    }, [page, pageSize]);

    useEffect(() => {
        if (filterJSON !== props.filterJSON || searchJSON !== props.searchJSON) {
            setPage(1);
            setPageSize(props.getReportingParams().count);
            setFilterJSON(props.filterJSON);
            setSearchJSON(props.searchJSON);
        }
    }, [props.filterJSON, props.searchJSON]);

    const handleChangePageSize = async (event) => {
        const newPageSize = event.target.value
        dispatch(updatePaginationOptionsPreferences(props.section, props.reportTitle, newPageSize, newPageSize == PageSizeOptions[0])).then(
            () => {
                setPageSize(parseInt(newPageSize));
                /*
                When the pageSize changes, the selected page may be outside of the
                possible range of pages. Rather than try to compute a new selected
                page (what would it even be?), it seems sensible to simply reset to
                the first page, which is always valid.
                 */
                setPage(1);
                dropdownRef.current.blur();
            }
        )
    };

    return totalRows && totalRows > 0 ? (
        <div className="new-table-pagination-controls">
            <div className="flexAlignCenter">
                <span>Rows per page:&nbsp;</span>
                <Select
                    inputRef={dropdownRef}
                    native
                    size="small"
                    value={pageSize}
                    input={<Input sx={{ fontSize: "1.6rem" }} />}
                    onChange={handleChangePageSize}
                    data-testid="paginationRowsPerPage"
                    id="paginationRowsPerPage" //Work-around for unexplained Playwright behavior. See comment in test "Pagination no unnecessary row count queries: Locations"
                    disabled={isLoading}
                >
                    {pageSizeOptions.map((option) => (
                        // <MenuItem value={option.value}>{option.label}</MenuItem>
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </div>
            {/* inline style {flex: "none"} required to make this div float all the way right */}
            <div
                className="flexAlignCenter"
                style={{ flex: "none" }}
                data-testid="paginationNav"
            >
                <FirstPageIcon
                    style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    fontSize="large"
                    onClick={() => !isLoading ? setPage(1) : null}
                    data-testid="paginationNavFirst"
                />
                <PrevPageIcon
                    style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    fontSize="large"
                    onClick={() => !isLoading ? setPage(Math.max(1, page - 1)) : null }
                    data-testid="paginationNavPrev"
                />
                <span>
                    page {page} of{" "}
                    {totalRows ? Math.ceil(totalRows / pageSize) : "??"}
                </span>
                <NextPageIcon
                    style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    fontSize="large"
                    onClick={() =>
                        ! isLoading ?
                            setPage(
                                Math.min(page + 1, Math.ceil(totalRows / pageSize))
                            )
                            : null
                    }
                    data-testid="paginationNavNext"
                    disabled={ isLoading }
                />
                <LastPageIcon
                    style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    fontSize="large"
                    onClick={() => !isLoading ? setPage(Math.ceil(totalRows / pageSize)) : null }
                    data-testid="paginationNavLast"
                />
            </div>
            <div data-testid="paginationTotalRowInfo" style={{minWidth: "16em"}}>
                {
                    isLoading ?
                    <span>LOADING</span>
                    :
                    <span>
                        &nbsp;{(page - 1) * pageSize + 1}-
                        {Math.min(page * pageSize, totalRows)} of{" "}
                        {totalRows ? totalRows : "??"} items
                    </span>
                }
            </div>
        </div>
    ) : (
        totalRows === undefined && (
            <div className="new-table-pagination-controls">
                <div className="flexAlignCenter">
                    <span>Rows per page:&nbsp;</span>
                    <Select
                        inputRef={dropdownRef}
                        native
                        size="small"
                        value={pageSize}
                        input={<Input sx={{ fontSize: "1.6rem" }} />}
                        onChange={handleChangePageSize}
                        data-testid="paginationRowsPerPage"
                        id="paginationRowsPerPage" //Work-around for unexplained Playwright behavior. See comment in test "Pagination no unnecessary row count queries: Locations"
                        disabled={isLoading}
                    >
                        {pageSizeOptions.map((option) => (
                            // <MenuItem value={option.value}>{option.label}</MenuItem>
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                </div>
                <div
                    className="flexAlignCenter"
                    style={{ flex: "none" }}
                    data-testid="paginationNav"
                >
                    <PrevPageIcon
                        style={{ cursor: !isLoading && page > 1 ? "pointer" : "not-allowed" }}
                        fontSize="large"
                        onClick={
                            !isLoading && page > 1
                                ? () => setPage(Math.max(0, props.page - 1))
                                : null
                        }
                        data-testid="paginationNavPrev"
                        disabled={isLoading}
                    />
                    <span>page {page}</span>
                    <NextPageIcon
                        style={{
                            cursor:
                                !isLoading && props.nextPage && props.nextPage > page
                                    ? "pointer"
                                    : "not-allowed",
                        }}
                        fontSize="large"
                        onClick={
                            !isLoading && props.nextPage && props.nextPage > page
                                ? () =>
                                      setPage(
                                          props.nextPage
                                              ? props.nextPage
                                              : props.page
                                      )
                                : null
                        }
                        data-testid="paginationNavNext"
                        disabled={isLoading || !props.nextPage || props.nextPage <= page}
                    />
                </div>
                <div data-testid="paginationTotalRowInfo" style={{minWidth: "8em"}}>
                {
                    isLoading
                    ? <span>LOADING</span>
                    : <span>&nbsp;</span>
                }
                </div>
            </div>
        )
    );
};

export default ReportTablePagination;
