import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import { Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-material.css";
import { sampleEventDataFields } from "./SampleReportDataEntryFields";
import { getSampleReportEvent, updateSampleReportEvent } from "../../../api/sampleReportsAPI";
import DateTimeEditor from "./DateTimeEditor";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import useAnalytes from "../useAnalytes";
import { getURLParentObject } from "../../../utils/url";

let rowIdCounter = 0; // Unique ID counter for new rows

const AddSampleReportEventData = () => {
    const history = useHistory();
    const { id } = useParams();
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [originalData, setOriginalData] = useState(null);
    const { analytes, loading: analytesLoading } = useAnalytes();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dueDate = searchParams.get("dueDate");

    const formattedDueDate =
        dueDate && dueDate !== "0" ? dayjs.unix(parseInt(dueDate)).format("MMM DD, YYYY - hh:mm A") : null;

    const cardTitle = formattedDueDate ? `Sampling Event (Due on ${formattedDueDate})` : "Sampling Event";

    // Fetch sample report event data
    useEffect(() => {
        if (!id) {
            console.error("No sample report event ID provided");
            return;
        }

        getSampleReportEvent(parseInt(id, 10))
            .then((data) => {
                console.log("Initial sample report event data:", data);
                setOriginalData(data);
                if (data?.sample_report_results) {
                    // Assign unique IDs to existing rows
                    const dataWithIds = data.sample_report_results.map((row, index) => ({
                        ...row,
                        id: `existing-${index}`, // Unique ID for existing rows
                    }));
                    setRowData(dataWithIds);
                }
            })
            .catch((error) => {
                console.error("Error fetching sample report event data:", error);
            });
    }, [id]);

    const handleSubmit = () => {
        // Get current grid data
        const currentGridData = [];
        gridRef.current.api.forEachNode((node) => {
            currentGridData.push(node.data);
        });

        console.log("Current grid data before processing:", currentGridData);

        // Filter out completely empty rows
        const nonEmptyRows = currentGridData.filter((row) => {
            return Object.entries(row).some(
                ([key, value]) =>
                    // Exclude id and sampling_config_analyte_id from empty check
                    key !== "id" &&
                    key !== "sampling_config_analyte_id" &&
                    value !== null &&
                    value !== undefined &&
                    value !== ""
            );
        });

        // Process the data for submission
        const processedData = nonEmptyRows.map((row) => {
            const originalRow = originalData?.sample_report_results.find((orig) => orig.analyte === row.analyte);

            console.log("Processing row for analyte:", row.analyte);
            console.log("Found original row:", originalRow);

            // Remove the 'id' field before submission
            const { id, ...rowWithoutId } = row;

            // Check if row has any data entered (besides analyte and sampling_config_analyte_id)
            const hasData = Object.entries(rowWithoutId).some(
                ([key, value]) =>
                    key !== "analyte" &&
                    key !== "sampling_config_analyte_id" &&
                    value !== null &&
                    value !== undefined &&
                    value !== ""
            );

            // If row has no data, return minimal object
            if (!hasData) {
                return {
                    analyte: row.analyte,
                    sampling_config_analyte_id: originalRow?.sampling_config_analyte_id || null,
                };
            }

            // If row has any data, validate required fields
            const requiredFields = [
                "sample_value",
                "sample_type",
                "sampled_by",
                "lab_method",
                "sample_start",
                "sample_end",
            ];

            const missingFields = requiredFields.filter((field) => !row[field] && row[field] !== 0);

            if (missingFields.length > 0) {
                throw new Error(`Missing required fields for analyte ${row.analyte}: ${missingFields.join(", ")}`);
            }

            const submissionRow = {
                // Include sample_report_result_id if it exists in the original row
                ...(originalRow?.sample_report_result_id && {
                    sample_report_result_id: originalRow.sample_report_result_id,
                }),

                // Required fields (not null in DB)
                sample_value: parseFloat(row.sample_value), // numeric, not null
                analyte: row.analyte, // text, not null
                sample_type: row.sample_type, // text, not null
                sampled_by: row.sampled_by, // text, not null
                lab_method: row.lab_method, // text, not null
                sample_start: row.sample_start, // timestamp with time zone, not null
                sample_end: row.sample_end, // timestamp with time zone, not null

                // Optional fields (nullable in DB)
                sampling_config_analyte_id: originalRow?.sampling_config_analyte_id || null, // Only include from original rows
                sampling_interval: row.sampling_interval ? parseInt(row.sampling_interval, 10) : null, // integer
                interval_unit: row.interval_unit || null, // text
                pacing: row.pacing || null, // text
                pacing_value: row.pacing_value ? parseInt(row.pacing_value, 10) : null, // integer
                pacing_unit: row.pacing_unit || null, // text
                aliquots: row.aliquots ? parseInt(row.aliquots, 10) : null, // integer
                container_type: row.container_type || null, // text
                container_volume: row.container_volume || null, // text
                preservative: row.preservative || null, // text
                hold_time: row.hold_time || null, // text
            };

            return submissionRow;
        });

        console.log("Final submission data:", processedData);

        updateSampleReportEvent(id, processedData)
            .then((response) => {
                console.log("Update successful:", response);
                history.goBack();
            })
            .catch((error) => {
                console.error("Error submitting sample report event data:", error);
            });
    };

    const handleCancel = () => {
        // Extract the sample report ID from the URL to navigate back
        const urlObject = getURLParentObject("sr");
        if (urlObject && urlObject.id) {
            history.push(`${urlObject.path}/${urlObject.id}`);
        }
    };

    const columnDefs = sampleEventDataFields.map((field) => {
        const isRequired = [
            "sample_value",
            "sample_type",
            "sampled_by",
            "lab_method",
            "sample_start",
            "sample_end",
        ].includes(field.id);

        const isDateTime = field.id === "sample_start" || field.id === "sample_end";
        const isSelect = field.inputType === "select";

        if (field.id === "analyte") {
            return {
                field: "analyte",
                headerName: "Analyte",
                editable: (params) => {
                    // Only allow editing for newly added rows (they will have an ID starting with "new-")
                    return params.data.id?.startsWith("new-");
                },
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: {
                    values: ["", ...analytes.map((a) => a.value)],
                    searchDebounceDelay: 200,
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                    valueListMaxHeight: 220,
                },
                valueFormatter: (params) => {
                    if (!params.value) return "";
                    const matchingAnalyte = analytes.find((a) => a.value === params.value);
                    return matchingAnalyte ? matchingAnalyte.label : params.value;
                },
                pinned: "left",
                lockPosition: true,
                suppressMovable: true,
                cellStyle: {
                    backgroundColor: "#f5f7f9",
                    borderRight: "1px solid #dde2eb",
                },
            };
        }

        return {
            field: field.id,
            headerName: isRequired ? `${field.label} *` : field.label,
            editable: true,
            cellEditor: isDateTime ? DateTimeEditor : isSelect ? "agSelectCellEditor" : undefined,
            cellEditorParams: isSelect
                ? {
                      values: ["", ...field.options.map((option) => option.value)],
                      defaultOption: "",
                  }
                : undefined,
            valueFormatter: isDateTime
                ? (params) => {
                      if (!params.value) return "";
                      try {
                          return dayjs.unix(params.value).format("MM/DD/YYYY hh:mm A");
                      } catch (e) {
                          console.error("Error formatting date:", e);
                          return params.value;
                      }
                  }
                : isSelect
                ? (params) => {
                      if (!params.value) return "";
                      const option = field.options.find((opt) => opt.value === params.value);
                      return option ? option.label : params.value;
                  }
                : undefined,
        };
    });

    const defaultColDef = {
        sortable: true,
        filter: false,
        resizable: true,
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        editable: true,
        autoHeight: false,
        wrapText: false,
        cellClass: "no-cell-expansion",
        cellStyle: {
            borderRight: "1px solid #dde2eb",
        },
        headerClass: "header-with-border",
    };

    const onCellValueChanged = (params) => {
        console.log("Cell value changed:", {
            field: params.column.getColId(),
            oldValue: params.oldValue,
            newValue: params.newValue,
            data: params.data,
            rowId: params.data.id,
        });

        // Handle both new entries (oldValue undefined) and edits (both values defined)
        if (params.newValue !== undefined && (params.oldValue === undefined || params.oldValue !== params.newValue)) {
            const updatedData = params.data[params.column.getColId()];
            if (updatedData !== params.newValue) {
                // Get current grid data
                const currentRows = [];
                gridRef.current.api.forEachNode((node) => {
                    currentRows.push(node.data);
                });

                // Update the specific row
                const updatedRows = currentRows.map((row) => {
                    if (row.id === params.data.id) {
                        return {
                            ...row,
                            [params.column.getColId()]: params.newValue,
                        };
                    }
                    return row;
                });

                // Update the state with all rows
                setRowData(updatedRows);
            }
        }
    };

    const cellSelection = React.useMemo(() => {
        return {
            handle: {
                mode: "fill",
                // Only allow vertical filling (down)
                direction: "y",
                // Prevent clearing values when reducing selection
                suppressClearOnFillReduction: true,
                // Custom fill value handler to ensure proper data types
                setFillValue: (params) => {
                    // Get the column definition to check the field type
                    const colDef = sampleEventDataFields.find((field) => field.id === params.column.getColId());

                    // Get the initial value from the first cell in the selection
                    const initialValue = params.initialValues[0];

                    // If it's a datetime field, maintain the current value
                    if (params.column.getColId() === "sample_start" || params.column.getColId() === "sample_end") {
                        return initialValue;
                    }

                    // For select fields, copy the selected value
                    if (colDef?.inputType === "select") {
                        return initialValue;
                    }

                    // For numeric fields, copy the numeric value
                    if (
                        params.column.getColId() === "sample_value" ||
                        params.column.getColId() === "sampling_interval" ||
                        params.column.getColId() === "pacing_value" ||
                        params.column.getColId() === "aliquots"
                    ) {
                        return initialValue;
                    }

                    // For all other fields, copy the initial value
                    return initialValue;
                },
            },
        };
    }, []);

    // Function to handle adding a new analyte
    const handleAddAnalyte = () => {
        // Get the current row count
        const currentRowCount = gridRef.current.api.getDisplayedRowCount();

        rowIdCounter += 1;

        // Create a new empty row
        const newRow = {
            id: `new-${rowIdCounter}`, // Unique ID for the new row
            analyte: "", // Empty analyte field that will be populated via dropdown
            sampling_config_analyte_id: null, // This being null indicates it's a new row
            sample_value: null,
            sample_type: null,
            sampled_by: null,
            lab_method: null,
            sample_start: null,
            sample_end: null,
            sampling_interval: null,
            interval_unit: null,
            pacing: null,
            pacing_value: null,
            pacing_unit: null,
            aliquots: null,
            container_type: null,
            container_volume: null,
            preservative: null,
            hold_time: null,
        };

        // Apply the transaction to add the new row at the bottom
        gridRef.current.api.applyTransaction({
            add: [newRow],
            addIndex: currentRowCount, // This ensures the new row is added at the bottom
        });

        // Scroll to the bottom to show the new row
        gridRef.current.api.ensureIndexVisible(currentRowCount, "bottom");
    };

    return (
        <Page>
            <Card title={cardTitle}>
                <style>{`
                    .ag-theme-material input[class^=ag-]:not([type]),
                    .ag-theme-material input[class^=ag-][type=text],
                    .ag-theme-material input[class^=ag-][type=number],
                    .ag-theme-material input[class^=ag-][type=tel],
                    .ag-theme-material input[class^=ag-][type=date],
                    .ag-theme-material input[class^=ag-][type=datetime-local],
                    .ag-theme-material textarea[class^=ag-] {
                        border-bottom: none !important;
                        padding-bottom: 0 !important;
                    }
                    .ag-theme-material .ag-cell-inline-editing {
                        height: var(--ag-row-height) !important;
                        padding: 0 var(--ag-grid-size) !important;
                        border: none !important;
                    }
                    .header-with-border {
                        border-right: 1px solid var(--neutral-1) !important;
                    }
                    .ag-theme-material .ag-header {
                        background-color: var(--bg-2);
                        border-bottom: 1px solid var(--neutral-2) !important;
                    }
                    .ag-theme-material .ag-header-cell {
                        background-color: var(--bg-2);
                    }

                    /* Base grid styles */
                    .ag-theme-material .ag-row {
                        border-bottom: 1px solid var(--ag-border-color) !important;
                    }
                    .ag-theme-material .ag-cell {
                        border-right: 1px solid #dde2eb;
                    }

                    /* Single cell selection style */
                    .ag-theme-material .ag-cell.ag-cell-focus {
                        outline: 1px solid #2196f3 !important;
                        outline-offset: -1px !important;
                    }

                    /* Cell selection styles */
                    .ag-theme-material .ag-cell.ag-cell-range-selected {
                        border: 1px solid #2196f3 !important;
                        background-color: rgba(33, 150, 243, 0.1) !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single) {
                        border: 1px solid #2196f3 !important;
                    }

                    /* Remove internal borders for selected range */
                    .ag-theme-material .ag-cell.ag-cell-range-selected:not(.ag-cell-range-top) {
                        border-top: none !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected:not(.ag-cell-range-bottom) {
                        border-bottom: none !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected:not(.ag-cell-range-right) {
                        border-right: none !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected:not(.ag-cell-range-left) {
                        border-left: none !important;
                    }

                    /* Override row borders for cells in selection range */
                    .ag-theme-material .ag-cell.ag-cell-range-selected {
                        border-bottom-color: transparent !important;
                    }
                    
                    /* Fill handle styles */
                    .ag-theme-material .ag-fill-handle {
                        background-color: #2196f3;
                        width: 8px;
                        height: 8px;
                        bottom: -4px;
                        right: -4px;
                    }
                    .ag-theme-material .ag-range-handle {
                        border: 1px solid #2196f3;
                    }

                    /* Style for cells being filled */
                    .ag-theme-material .ag-cell-range-selected.ag-cell-range-chart:not(.ag-cell-range-single) {
                        border: 1px dashed #2196f3 !important;
                    }

                    /* Ensure outer borders of selection are always visible */
                    .ag-theme-material .ag-cell.ag-cell-range-selected.ag-cell-range-top {
                        border-top: 1px solid #2196f3 !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected.ag-cell-range-right {
                        border-right: 1px solid #2196f3 !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected.ag-cell-range-bottom {
                        border-bottom: 1px solid #2196f3 !important;
                    }
                    .ag-theme-material .ag-cell.ag-cell-range-selected.ag-cell-range-left {
                        border-left: 1px solid #2196f3 !important;
                    }

                    /* No cell expansion */
                    .no-cell-expansion {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                `}</style>
                <div
                    className="ag-theme-material"
                    style={{
                        height: "600px",
                        width: "100%",
                        marginTop: "1rem",
                        overflow: "hidden",
                        border: "1px solid var(--neutral-2)",
                        borderRadius: "4px",
                        "--ag-borders": "none",
                        "--ag-borders-critical": "none",
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressColumnVirtualisation={true}
                        onCellValueChanged={onCellValueChanged}
                        cellSelection={cellSelection}
                        enableRangeSelection={true}
                        enableFillHandle={true}
                    />
                </div>
                <div
                    style={{
                        padding: "1rem",
                        borderTop: "1px solid var(--ag-border-color)",
                        backgroundColor: "white",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddAnalyte}
                        startIcon={<AddCircleIcon />}
                        disabled={analytesLoading}
                        sx={{
                            fontSize: "1.4rem",
                            padding: "6px 16px",
                            textTransform: "none",
                        }}
                    >
                        Add New Analyte
                    </Button>
                </div>

                {/* Bottom buttons container */}
                <div
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        sx={{
                            fontSize: "1.4rem",
                            padding: "10px 32px",
                            minWidth: "150px",
                            height: "42px",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                            padding: "10px 32px",
                            fontSize: "1.4rem",
                            minWidth: "150px",
                            height: "42px",
                        }}
                    >
                        Save and Exit
                    </Button>
                </div>
            </Card>
        </Page>
    );
};

export default AddSampleReportEventData;
