import React from "react";
import styles from "../../styles/common/card.module.css";
import { AddRounded, EditRounded, DeleteRounded } from "@mui/icons-material";
import IconButton from "./IconButton";
import Divider from "./Divider";
import Button from "./Button";

/**
 * Basic card with rounded corners and drop-shadow, with optional title/chip/edit in header area
 *
 * @param {string} [title] - Optional title displayed in the card header
 * @param {JSX.Element} [chip] - Optional chip to be displayed next to title
 * @param {JSX.Element} [customCardHeaderContent] - Optional custom content for the right side of header
 * @param {() => void} [onEdit] - Callback for clicking the "edit" icon (leave undefined to hide icon)
 * @param {() => void} [onCreate] - Callback for clicking the "create" button (leave undefined to hide button)
 * @param {string} [onCreateLabel] - Text displayed on the "create" button
 * @param {() => void} [onDelete] - Callback for clicking the "delete" button (leave undefined to hide button)
 * @param {boolean} [showDelete] - Flag to control the visibility of the delete button
 * @param {React.ReactNode} [children] - Content to display in card body
 */
const Card = ({
    title,
    chip,
    customCardHeaderContent,
    onEdit,
    onCreate,
    onCreateLabel,
    onDelete,
    showDelete,
    children,
}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.left}>
                    {title && <h3 className={styles.title}>{title}</h3>}
                    {chip}
                </div>
                <div className={styles.right}>
                    {customCardHeaderContent ? (
                        customCardHeaderContent
                    ) : (
                        <>
                            {onEdit && (
                                <IconButton
                                    data-testid={`edit ${title} button`}
                                    icon={<EditRounded />}
                                    onClick={onEdit}
                                    size="large"
                                />
                            )}
                            {onCreate && (
                                <Button onClick={onCreate} color="tertiary" data-testid={`${onCreateLabel} button`}>
                                    <AddRounded /> {onCreateLabel}
                                </Button>
                            )}
                            {onDelete && showDelete && (
                                <Button onClick={onDelete} className={styles.deleteButton}>
                                    <DeleteRounded style={{ fontSize: "1.7rem" }} /> Delete
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
            {(title || chip) && <Divider />}
            <div className={styles.body}>{children}</div>
        </div>
    );
};

export default Card;
