import {
    SET_USER_REPORTING_OPTIONS_PREFERENCES,
    SET_USER_PAGINATION_OPTIONS_PREFERENCES,
} from "../actions/swiftComplyActions";

const initialState = {};

const setUserPreferencesHelper = (state, action) => {
    let parsed = null;

    try {
        parsed = JSON.parse(action.data?.[action.key]);
    }
    catch {
        action.data[action.key] = "{}";
    }

    if (!parsed || typeof parsed !== 'object' || Array.isArray(parsed)) {
        action.data[action.key] = "{}";
    }

    return {
        ...state,
        user: {
            ...state.user,
            preferences: action.data,
        },
    };
}

const swiftComplyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_REPORTING_OPTIONS_PREFERENCES:
            return setUserPreferencesHelper(state, action)
        
        case SET_USER_PAGINATION_OPTIONS_PREFERENCES:
            return setUserPreferencesHelper(state, action)

        default:
            return state;
    }
};

export default swiftComplyReducer;
