import { createTheme } from "@mui/material/styles";

export const backflowTheme = createTheme({
    typography: {
        fontFamily: ["Heebo", "Arial", "sans-serif"].join(","),
        fontWeightBold: 700,
        fontWeightRegular: 500,
        body1: {
            fontSize: "1.5rem",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: "Heebo, Arial, sans-serif",
                    fontSize: "1.6rem",
                    letterSpacing: "0.05em",
                    color: "#232F42",
                    backgroundColor: "#F5F7F9",
                    lineHeight: 1.6,
                    margin: 0,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: {
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                edgeEnd: {
                    padding: "16px",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
    },
});

export const pretreatmentTheme = createTheme({
    typography: {
        fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
        body1: {
            fontSize: "1.5rem",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: "Roboto, Arial, sans-serif",
                    fontSize: "1.6rem",
                    letterSpacing: "0.05em",
                    color: "#232F42",
                    backgroundColor: "#F5F7F9",
                    lineHeight: 1.6,
                    margin: 0,
                },
            },
        },
    },
});
