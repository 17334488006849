import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { defaultGridStyles, defaultGridOptions, defaultColDefs, gridWrapperStyles } from "../../../styles/agGridStyles";

import { Button, Select, MenuItem, IconButton, Tooltip } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { FirstPageRounded, ChevronLeftRounded, ChevronRightRounded, LastPageRounded } from "@mui/icons-material";

import { getReportSync, downloadReport } from "../../../api/reportingAPI";
import { createColumnDefsFromReport, transformReportToGridData } from "../../../utils/agGridUtils";
import { useDispatch, useSelector } from "react-redux";
import { updateReportingOptionsPreferences } from "../../../store/actions/swiftComplyActions";
import { getURLBaseSection } from "../../../utils/url";
import { dateToAPIString } from "../../../utils/forms";

import ColumnMenu from "./ColumnMenu";

const DynamicReportTable = ({
    reportUUID,
    tableTitle,
    defaultColumns,
    columnOverrides = {},
    gridOptions = {},
    customStyles = {},
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.swiftComply);
    const baseSection = getURLBaseSection().section;
    const options = JSON.parse(user?.preferences?.["reporting options"] || "{}");
    const { columns: savedColumns } = options[baseSection]?.[tableTitle] ?? {};
    const columns = savedColumns?.length ? savedColumns : defaultColumns;

    // AG Grid / advanced filter references
    const gridRef = useRef(null);
    const advancedFilterBarRef = useRef(null);

    // Store grid API separately
    const [gridApi, setGridApi] = useState(null);

    // Data & columns
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [allColumns, setAllColumns] = useState([]);
    const [originalColumnDefs, setOriginalColumnDefs] = useState([]);

    // If advancedSearch != null, we'll send that to the server on each fetch
    const [advancedSearch, setAdvancedSearch] = useState(null);

    // Track whether the Clear button should be disabled
    const [isClearDisabled, setIsClearDisabled] = useState(true);

    // Basic loading indicator
    const [loading, setLoading] = useState(false);

    // Sorting state
    const [sortOrder, setSortOrder] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const rowCountOptions = [10, 25, 50, 100];

    // Advanced filter localization
    const localeText = {
        advancedFilterBuilderApply: "Apply Advanced Filter",
        advancedFilterBuilderCancel: "Cancel",
    };

    // Additional advanced filter builder configuration
    const advancedFilterBuilderParams = {
        showMoveButtons: true,
    };

    // Add sortTimeout ref to track/clear timeouts
    const sortTimeoutRef = useRef(null);

    /**
     * Helper to check if the advanced filter model is empty.
     * We'll use this to control whether the Clear button is disabled.
     */
    const isFilterModelEmpty = useCallback((model) => {
        if (!model) {
            // No model at all => empty
            return true;
        }

        if (model.filterType === "join") {
            // It's a group of conditions with AND/OR
            if (!model.conditions || model.conditions.length === 0) {
                return true;
            }
            // Check if every child is empty
            return model.conditions.every((cond) => isFilterModelEmpty(cond));
        } else {
            // For an individual leaf condition, see if 'filter' has content
            // (Sometimes an empty text search might be "")
            return !model.filter || model.filter.length === 0;
        }
    }, []);

    /**
     * Whenever advancedSearch changes (set by onFilterChanged), we fetch data from the server.
     */
    useEffect(() => {
        let isMounted = true;
        if (!reportUUID) return;

        const fetchReportData = async () => {
            setLoading(true);
            try {
                const response = await getReportSync(reportUUID, {
                    advanced_search: advancedSearch,
                    page: currentPage,
                    count: rowsPerPage,
                    ...(sortOrder && {
                        order: sortOrder,
                        direction: sortDirection,
                    }),
                });

                if (!isMounted) return;

                console.log("API Response:", response);
                // Handle both response structures
                const totalCount = response.totalRowCount || response.data?.rows?.length || 0;
                setTotalRowCount(Number(totalCount));

                const reportData = response.data;

                // Transform the data
                let transformedData = transformReportToGridData(reportData);

                // Convert numeric fields from string -> number
                transformedData = transformedData.map((row) => {
                    const newRow = { ...row };
                    reportData.outputs.forEach((output) => {
                        if (output.type === "numeric" && newRow[output.name] !== "") {
                            newRow[output.name] = Number(newRow[output.name]);
                        }
                    });
                    return newRow;
                });

                setRowData(transformedData);

                // Build column defs
                let cols = createColumnDefsFromReport(reportData.outputs);

                // Add filter specifics
                cols = cols.map((col) => {
                    const outputDef = reportData.outputs.find((o) => o.name === col.field);
                    const baseCol = { ...col, type: outputDef?.type };

                    switch (outputDef?.type) {
                        case "timestamp":
                            return {
                                ...baseCol,
                                cellDataType: "dateString",
                                filter: "agDateColumnFilter",
                                filterParams: {
                                    browserDatePicker: true,
                                    filterOptions: [
                                        "equals",
                                        "notEqual",
                                        "lessThan",
                                        "greaterThan",
                                        "lessThanOrEqual",
                                        "greaterThanOrEqual",
                                    ],
                                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                                        if (!cellValue) return 0;
                                        const cellDate = new Date(cellValue).getTime();
                                        const filterDate = new Date(filterLocalDateAtMidnight).getTime();
                                        return cellDate < filterDate ? -1 : cellDate > filterDate ? 1 : 0;
                                    },
                                },
                                valueFormatter: (params) => {
                                    if (!params.value) return "";
                                    const date = new Date(params.value);
                                    return date.toLocaleString("en-US", {
                                        month: "2-digit",
                                        day: "numeric",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    });
                                },
                                valueParser: (params) => {
                                    if (!params.newValue) return null;
                                    const date = new Date(params.newValue);
                                    return isNaN(date.getTime()) ? null : date.toISOString();
                                },
                            };
                        case "numeric":
                            return {
                                ...baseCol,
                                cellDataType: "number",
                                filter: "agNumberColumnFilter",
                                filterParams: {
                                    filterOptions: [
                                        "equals",
                                        "notEqual",
                                        "lessThan",
                                        "greaterThan",
                                        "lessThanOrEqual",
                                        "greaterThanOrEqual",
                                    ],
                                    numberParser: (text) => {
                                        if (!text) return null;
                                        const parsed = parseFloat(text);
                                        return isNaN(parsed) ? null : parsed;
                                    },
                                },
                                valueFormatter: (params) => {
                                    if (params.value == null) return "";
                                    const parsed = parseFloat(params.value);
                                    if (isNaN(parsed)) return params.value;
                                    // Remove trailing zeros if decimal
                                    return parsed % 1 !== 0 ? parsed.toFixed(2).replace(/\.?0+$/, "") : String(parsed);
                                },
                                valueParser: (params) => {
                                    if (!params.newValue) return null;
                                    const parsed = parseFloat(params.newValue);
                                    return isNaN(parsed) ? null : parsed;
                                },
                            };
                        default:
                            return {
                                ...baseCol,
                                cellDataType: "text",
                                filterParams: {
                                    filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
                                },
                            };
                    }
                });

                setOriginalColumnDefs(cols);
                setAllColumns(cols.map((col) => col.field).filter((f) => !f.match(/uuid/i)));
            } catch (error) {
                console.error("Error fetching report data:", error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchReportData();

        return () => {
            isMounted = false;
        };
    }, [reportUUID, advancedSearch, currentPage, rowsPerPage, sortOrder, sortDirection]);

    /**
     * Once we have the raw columns, apply user-specified overrides or
     * hide columns that the user doesn't want to see (based on `columns` array).
     */
    useEffect(() => {
        if (originalColumnDefs.length === 0) return;

        let updatedCols = [...originalColumnDefs];

        if (columns?.length > 0) {
            updatedCols = updatedCols.filter((col) => columns.includes(col.field));
        }
        // Apply overrides
        updatedCols = updatedCols.map((col) => ({
            ...col,
            ...columnOverrides[col.field],
        }));

        const currentStr = JSON.stringify(columnDefs);
        const newStr = JSON.stringify(updatedCols);
        if (currentStr !== newStr) {
            setColumnDefs(updatedCols);
        }
    }, [columns, originalColumnDefs, columnOverrides, columnDefs]);

    /**
     * onFilterChanged - Called whenever the user modifies the advanced filter or hits "Apply".
     * We read the advanced filter model and convert it for server usage.
     */
    const onFilterChanged = useCallback(() => {
        if (!gridApi) return;
        const filterModel = gridApi.getAdvancedFilterModel();

        // Convert date strings, etc.
        const processFilterModel = (model) => {
            if (!model) return model;
            if (model.filterType === "join") {
                return {
                    ...model,
                    conditions: model.conditions.map((cond) => processFilterModel(cond)),
                };
            }
            if (model.filterType === "dateString") {
                return {
                    ...model,
                    filter: dateToAPIString(new Date(model.filter)),
                };
            }
            return { ...model, filter: String(model.filter) };
        };

        const processed = processFilterModel(filterModel);
        setAdvancedSearch(processed);

        // Reset to first page when filters change
        setCurrentPage(1);

        setIsClearDisabled(isFilterModelEmpty(filterModel));
    }, [gridApi, isFilterModelEmpty]);

    /**
     * Called once on gridReady. We store the API and re-route the advanced filter bar
     * into our custom advancedFilterBarRef.
     */
    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        gridRef.current = params.api;

        // Show advanced filter in our custom container
        params.api.setGridOption("advancedFilterParent", advancedFilterBarRef.current);
    }, []);

    /**
     * Clears all advanced filters, resets the search to null, and fetches the full dataset.
     */
    const handleClearAdvancedFilter = useCallback(() => {
        if (!gridApi) return;

        // 1) Clear on the AG Grid side
        gridApi.setAdvancedFilterModel(null);

        // 2) Reset our local state so the next fetch is unfiltered
        setAdvancedSearch(null);

        // 3) Reset to first page when clearing filters
        setCurrentPage(1);

        // 4) Since we just cleared it, disable the clear button
        setIsClearDisabled(true);
    }, [gridApi]);

    // Function to check if a column is used in the filter model
    const isColumnInFilter = useCallback((colId, filterModel) => {
        if (!filterModel) return false;

        if (filterModel.filterType === "join") {
            return filterModel.conditions.some((condition) => isColumnInFilter(colId, condition));
        }

        return filterModel.colId === colId;
    }, []);

    // Create a list of columns that should be disabled
    const getDisabledColumns = useCallback(() => {
        if (!gridApi) return [];
        const filterModel = gridApi.getAdvancedFilterModel();
        if (!filterModel) return [];

        return allColumns.filter((colId) => isColumnInFilter(colId, filterModel));
    }, [gridApi, allColumns, isColumnInFilter]);

    const onSortChanged = useCallback(() => {
        if (!gridApi) {
            console.warn("Column API not available for sorting");
            return;
        }

        // Clear any existing timeout
        if (sortTimeoutRef.current) {
            clearTimeout(sortTimeoutRef.current);
        }

        const columnState = gridApi.getColumnState();
        const sortedColumn = columnState.find((col) => col.sort);

        console.log("Sort changed:", { columnState, sortedColumn });

        // This is a hack to make the sorting animation look smooth, the report is
        // Fetched and rendered so quickly that the default AG Grid animation
        // Is not visible.
        sortTimeoutRef.current = setTimeout(() => {
            if (sortedColumn) {
                setSortOrder(sortedColumn.colId);
                setSortDirection(sortedColumn.sort === "asc" ? "asc" : "desc");
                setCurrentPage(1); // Reset to first page when sort changes
            } else {
                setSortOrder(null);
                setSortDirection("asc");
            }
        }, 300);
    }, [gridApi]);

    // Clean up timeout on unmount
    useEffect(() => {
        return () => {
            if (sortTimeoutRef.current) {
                clearTimeout(sortTimeoutRef.current);
            }
        };
    }, []);

    const totalPages = Math.ceil(totalRowCount / rowsPerPage);
    const firstRow = totalRowCount === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1;
    const lastRow = Math.min(currentPage * rowsPerPage, totalRowCount);

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
    };

    // Component specific styles
    const componentSpecificStyles = {
        borderRadius: "8px",
        ...customStyles,
    };

    // Merge grid options
    const mergedGridOptions = {
        ...defaultGridOptions,
        ...gridOptions,
        columnTypes: {
            string: {},
            numeric: {
                filter: "agNumberColumnFilter",
                cellDataType: "number",
            },
            timestamp: {
                filter: "agDateColumnFilter",
                cellDataType: "dateString",
            },
        },
    };

    const handleDownload = useCallback(async () => {
        if (!gridApi) {
            console.error("Grid API not available");
            return;
        }

        // Get visible columns
        const visibleColumns = gridApi
            .getColumns()
            .filter((col) => col.isVisible())
            .map((col) => col.getColDef().field)
            .filter(Boolean);

        // Get columns in their display order
        const columnOrder = gridApi
            .getAllGridColumns()
            .filter((col) => col.isVisible())
            .map((col) => col.getColDef().field)
            .filter(Boolean);

        // Construct the report parameters
        const reportParams = {
            columns: visibleColumns,
            column_order: columnOrder,
            report_request: {
                page: currentPage,
                nextPage: currentPage,
                count: rowsPerPage,
                direction: sortDirection,
                order: sortOrder,
                advanced_search: {},
                inputs: {},
            },
            export_all: false,
        };

        // Add advanced search if present
        if (advancedSearch) {
            reportParams.report_request.advanced_search = advancedSearch;
        }

        try {
            await downloadReport(reportUUID, reportParams, "Sample Results");
        } catch (error) {
            console.error("Error downloading report:", error);
        }
    }, [gridApi, reportUUID, tableTitle, currentPage, rowsPerPage, sortDirection, sortOrder, advancedSearch]);

    return (
        <div style={gridWrapperStyles}>
            <style>
                {`
                    .ag-header-row {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
                    }
                    .ag-header-cell-label {
                        font-weight: 600 !important;
                    }
                    .MuiTooltip-tooltip {
                        font-size: 12px !important;
                    }
                    .ag-header-icon.ag-header-cell-menu-button {
                        display: none !important;
                    }
                `}
            </style>

            {/* 
                One unified toolbar: 
                - Left side: The advanced filter bar (search, Apply, Builder) 
                - Our custom "Clear" button 
                - Right side: (Download, Save, Column Menu)
            */}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    gap: "16px",
                    backgroundColor: "#fbfbfb",
                }}
            >
                {/* The advanced filter bar will inject its search box, Apply & Builder buttons here */}
                <div
                    ref={advancedFilterBarRef}
                    style={{ flexGrow: 1, display: "flex", alignItems: "center", gap: "10px" }}
                >
                    <style>
                        {`
                            /* Target the container that holds the search bar and buttons */
                            .ag-advanced-filter-toolbar-container {
                                display: flex !important;
                                gap: 8px !important;
                            }
                            
                            /* Search input */
                            .ag-advanced-filter-toolbar-container > :first-child {
                                order: 1;
                            }
                            
                            /* Apply button */
                            .ag-advanced-filter-toolbar-container > :nth-child(2) {
                                order: 2;
                            }
                            
                            /* Clear button */
                            .ag-advanced-filter-toolbar-container > .MuiButton-root {
                                order: 3;
                            }
                            
                            /* Advanced Filter button */
                            .ag-advanced-filter-toolbar-container > button:last-child {
                                order: 4;
                            }
                        `}
                    </style>
                    <Button
                        variant="outlined"
                        disabled={isClearDisabled}
                        onClick={handleClearAdvancedFilter}
                        style={{ order: 3 }}
                    >
                        Clear
                    </Button>
                </div>

                {/* Right side icons */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Export to CSV" placement="top" arrow>
                        <IconButton onClick={handleDownload} disabled={!gridApi || loading}>
                            <DownloadRoundedIcon style={{ cursor: "pointer", fontSize: "20px" }} />
                        </IconButton>
                    </Tooltip>
                    {/* TODO: Add save icon when filter saving feature is built */}
                    {/* <SaveRoundedIcon style={{ cursor: "pointer", fontSize: "20px" }} /> */}
                    {!!allColumns?.length && (
                        <ColumnMenu
                            columns={allColumns}
                            disabledColumns={getDisabledColumns()}
                            onApply={(selectedColumns) =>
                                dispatch(updateReportingOptionsPreferences(baseSection, tableTitle, selectedColumns))
                            }
                        />
                    )}
                </div>
            </div>

            {/* AG Grid with advanced filter enabled and local filtering suppressed. */}
            <div className="ag-theme-quartz" style={{ ...defaultGridStyles, ...componentSpecificStyles }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDefs}
                    enableAdvancedFilter
                    showAdvancedFilterToolbar={false}
                    suppressAdvancedFilterEval
                    suppressClientSideSort={true}
                    advancedFilterBuilderParams={advancedFilterBuilderParams}
                    localeText={localeText}
                    onFilterChanged={onFilterChanged}
                    onGridReady={onGridReady}
                    onSortChanged={onSortChanged}
                    suppressMultiSort={true}
                    popupParent={document.body}
                    suppressScrollOnNewData={true}
                    {...mergedGridOptions}
                    pagination={false}
                />
            </div>

            {/* Show pagination when we have any rows */}
            {totalRowCount > 0 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px",
                        fontSize: "14px",
                        borderTop: "1px solid #e0ebec",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <span>Rows per page:</span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            variant="standard"
                            size="small"
                            sx={{
                                fontSize: "14px",
                                "&:before": {
                                    borderBottom: "none",
                                },
                                "&:after": {
                                    borderBottom: "none",
                                },
                                "& .MuiInput-input": {
                                    fontSize: "14px",
                                    color: "black",
                                },
                            }}
                        >
                            {rowCountOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <span>
                            <IconButton onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                                <FirstPageRounded />
                            </IconButton>
                        </span>

                        <span>
                            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                <ChevronLeftRounded />
                            </IconButton>
                        </span>

                        <span>
                            Page {currentPage} of {totalPages}
                        </span>

                        <span>
                            <IconButton
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage >= totalPages}
                            >
                                <ChevronRightRounded />
                            </IconButton>
                        </span>

                        <span>
                            <IconButton
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage >= totalPages}
                            >
                                <LastPageRounded />
                            </IconButton>
                        </span>
                    </div>

                    <div>
                        {firstRow}-{lastRow} of {totalRowCount} rows
                    </div>
                </div>
            )}
        </div>
    );
};

export default DynamicReportTable;
