import React from "react";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PopUpMenuForm from "../PopUpMenuForm";
import ToolTip from "../ToolTip";

const ColumnMenu = ({ columns, selectedColumns, onApply, disabledColumns = [] }) => {
    return (
        <PopUpMenuForm
            icon={
                <ToolTip title="Table Columns" placement="top" arrow>
                    <SettingsRoundedIcon />
                </ToolTip>
            }
            title="Select columns"
            formFields={columns.map((col) => ({
                id: col,
                label: col,
                inputType: "checkbox",
                dataType: "boolean",
                disabled: disabledColumns.includes(col),
                tooltip: disabledColumns.includes(col) ? "This column is used in the current filter" : undefined,
            }))}
            initialFormState={(selectedColumns?.length ? selectedColumns : columns).reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr]: true,
                }),
                {}
            )}
            onApply={(formState) =>
                onApply(
                    Object.entries(formState)
                        .filter((col) => col[1])
                        .map((col) => col[0])
                )
            }
        />
    );
};

export default ColumnMenu;
