export const gridWrapperStyles = {
    border: "1px solid #e0ebec",
    borderRadius: "2px",
    backgroundColor: "#ffffff", // --bg-1
};

const calculateGridHeight = (headerHeight, rowHeight, rowCount, rowBorderWidth = 1) => {
    const totalRowHeight = rowCount * (rowHeight + rowBorderWidth);
    return `${headerHeight + totalRowHeight}px`;
};

export const defaultGridStyles = {
    height: calculateGridHeight(48, 42, 10, 2),
    width: "100%",
    "--ag-header-height": "48px",
    "--ag-row-height": "42px",
    "--ag-header-foreground-color": "#1A1A1A",
    "--ag-header-background-color": "#FBFBFB",
    "--ag-odd-row-background-color": "#FFFFFF",
    "--ag-row-border-color": "rgba(0, 0, 0, 0.12)",
    "--ag-font-size": "14px",
    "--ag-font-family": "Inter, sans-serif",
    "--ag-borders": "none",
};

export const defaultGridOptions = {
    suppressDragLeaveHidesColumns: true,
    suppressScrollOnNewData: true,
    popupParent: document.body,
};

export const denseGridStyles = {
    ...defaultGridStyles,
    "--ag-row-height": "36px",
    height: "588px",
};

export const tallGridStyles = {
    ...defaultGridStyles,
    height: "800px",
};

// Common column definitions that might be reused
export const defaultColDefs = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 100,
};
