/**
 * Configuration management for the application.
 * Centralizes access to environment variables and configuration values.
 *
 * Note: Values are injected by webpack.DefinePlugin during build time.
 * The plugin performs direct text replacement, so we need to ensure proper
 * string handling when the values are used.
 */

// Define all configuration keys
export const CONFIG_KEYS = {
    // Launch Darkly client ID for feature flags
    // Injected as: LAUNCH_DARKLY_CLIENT_ID: JSON.stringify(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID)
    LAUNCH_DARKLY_CLIENT_ID: "LAUNCH_DARKLY_CLIENT_ID",

    // AG Grid Enterprise license key
    // Injected as: AG_GRID_LICENSE_KEY: JSON.stringify(process.env.REACT_APP_AG_GRID_LICENSE_KEY)
    AG_GRID_LICENSE_KEY: "AG_GRID_LICENSE_KEY",
};

// Configuration values initialized from webpack's DefinePlugin
// These global variables are replaced at build time
const CONFIG_VALUES = {
    [CONFIG_KEYS.LAUNCH_DARKLY_CLIENT_ID]:
        typeof LAUNCH_DARKLY_CLIENT_ID === "string"
            ? LAUNCH_DARKLY_CLIENT_ID
            : JSON.parse(LAUNCH_DARKLY_CLIENT_ID),

    [CONFIG_KEYS.AG_GRID_LICENSE_KEY]:
        typeof AG_GRID_LICENSE_KEY === "string"
            ? AG_GRID_LICENSE_KEY
            : JSON.parse(AG_GRID_LICENSE_KEY),
};

/**
 * Retrieves a configuration value by key.
 * Handles the string parsing that may be needed due to webpack.DefinePlugin's
 * string replacement behavior.
 *
 * @param {string} key - The configuration key to retrieve
 * @returns {string|undefined} The configuration value
 * @throws {Error} If the configuration key doesn't exist
 */
export const getConfig = (key) => {
    const value = CONFIG_VALUES[key];

    if (value === undefined) {
        console.error(`Configuration value not found for key: ${key}`);
        return undefined;
    }

    // Handle potential double-quoted strings from DefinePlugin
    return value.replace(/^"|"$/g, "");
};

/**
 * Checks if a configuration value exists and is not undefined/null
 * @param {string} key - The configuration key to check
 * @returns {boolean} Whether the configuration value exists and is set
 */
export const hasConfig = (key) => {
    const value = CONFIG_VALUES[key];
    return value != null && value !== "undefined" && value !== "null";
};
