import { LicenseManager } from "ag-grid-enterprise";
import { getConfig, CONFIG_KEYS, hasConfig } from "./config";

// Initialize AG Grid
export const initializeAgGrid = () => {
    if (hasConfig(CONFIG_KEYS.AG_GRID_LICENSE_KEY)) {
        LicenseManager.setLicenseKey(getConfig(CONFIG_KEYS.AG_GRID_LICENSE_KEY));
    } else {
        console.warn("AG Grid license key not found");
    }
};
