import React from "react";
import Home from "../../Common/Home/Home";
import DynamicReportTable from "../../Common/ReportTable/DynamicReportTable";
// import { Select, MenuItem } from "@mui/material";

const SamplingResultsHome = () => {
    // const [viewType, setViewType] = React.useState("standard");

    // EXAMPLE CUSTOM HEADER CONTENT (COMMENTED OUT FOR NOW,
    // THIS IS AN EXAMPLE IMPLEMENTATION)
    // const headerContent = (
    //     <Select
    //         value={viewType}
    //         onChange={(e) => setViewType(e.target.value)}
    //         size="small"
    //         sx={{
    //             minWidth: 150,
    //             height: 40,
    //             backgroundColor: "white",
    //             "& .MuiSelect-select": {
    //                 paddingTop: "8px",
    //                 paddingBottom: "8px",
    //             },
    //         }}
    //     >
    //         <MenuItem value="standard">Standard view</MenuItem>
    //         <MenuItem value="detailed">Detailed view</MenuItem>
    //         <MenuItem value="compact">Compact view</MenuItem>
    //     </Select>
    // );

    return (
        <Home
            cardTitle="Sampling Results"
            // customCardHeaderContent={headerContent}
        >
            <DynamicReportTable reportUUID="02c81e5b-a9c3-40ec-81e7-6126ec101387" />
        </Home>
    );
};

export default SamplingResultsHome;
